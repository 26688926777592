import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { OFFER_PERIOD_TYPE_SINGLE } from 'Consts/offers';

import { translateDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

export default class PublicShopOfferPresentation extends Component {
    static propTypes = {
        offer: PropTypes.object.isRequired,
        onComplete: PropTypes.func.isRequired,
    };
    static defaultProps = {};
    state = {};
    specificSlugs = [
        'dostep-do-fitadept-tv-oraz-mindy-app',
    ]

    render() {
        const { offer, onComplete } = this.props;
        
        return (
            <StyledComponent
                className="public-shop-offer-presentation"
                styles={require('./styles')}
            >
                <h1 className="headline">
                    {offer?.name}
                </h1>
                {offer?.description && (
                    <p className="description" dangerouslySetInnerHTML={{ __html: offer?.description }} />
                )}
                <div className="offer-rows"> 
                    <div className="offer-row offer-row-details">
                        {offer?.usps?.length > 0 && (
                            <div className="block block-usps">
                                <h3 className="block-headline">Z nami zyskujesz:</h3>
                                <div className="block-list">
                                    {offer?.usps?.map(offerUsp => (
                                        <li className="block-list-element" key={offerUsp.id}>
                                            {offerUsp.content}
                                        </li>
                                    ))}
                                </div>
                            </div>
                        )}
                        {!this.specificSlugs.includes(offer?.slug) && offer?.products?.length > 0 && (
                            <div className="block block-products">
                                <h3 className="block-headline">Zawartość oferty:</h3>
                                <div className="block-list">
                                    {offer?.products?.map(offerProduct => (
                                        <div className="block-element" key={offerProduct?.id}>
                                            <div className="block-element-quantity">
                                                <p className="quantity-prefix">
                                                    x
                                                </p>
                                                <p>
                                                    {offerProduct.quantity}
                                                </p>
                                            </div>
                                            <div className="block-element-product-name">{offerProduct.product.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        
                    </div>
                    <div className="offer-row offer-row-pricing">
                        <div className="block block-pricing">
                            <div className="block-summary">
                                <div className="block-row block-row-price">
                                    <div className="block-row-label">
                                        Cena: 
                                    </div>
                                    <div className="block-row-value">
                                        {formatPrice(offer?.totalPriceGross)}
                                    </div>
                                </div>
                                {this.specificSlugs.includes(offer?.slug) && (
                                    <div className="block-row additional-price-info">
                                        <div className="additional-price-label">
                                            Regularna cena: 180zł
                                        </div>
                                    </div>
                                )}
                                {Boolean(offer?.durationValue) && (
                                    offer?.periodType === OFFER_PERIOD_TYPE_SINGLE 
                                        ? (
                                            <div className="block-row block-row-period">
                                                <div className="block-row-label">
                                                    Płatność jednorazowa
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="block-row block-row-period">
                                                <div className="block-row-label">
                                                    Płatność cykliczna pobierana co:  {offer?.durationValue} {translateDate(offer?.durationType, offer?.durationValue)}
                                                </div>
                                                {Boolean(offer?.noticePeriodDurationValue == 0) && (
                                                    <div className="block-row-value">
                                                        Możesz zrezygnować w dowolnym momencie
                                                    </div>
                                                )}
                                            </div>
                                        )
                                )}
                                {Boolean(offer?.noticePeriodDurationValue) && (
                                    <div className="block-row block-row-notice">
                                        <div className="block-row-label">
                                            Okres wypowiedzenia: {offer?.noticePeriodDurationValue} {translateDate(offer?.noticePeriodDurationType, offer?.noticePeriodDurationValue)}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="block-footer">
                                <Button
                                    style="gradient"
                                    size="large"
                                    onClick={onComplete}
                                    layout="fullWidth"
                                >
                                    Dalej {'>>>'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledComponent>
        );
    }
}