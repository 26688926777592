import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background: ${variables.dpc_backgroundWhite};
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .layout-container {
        display: flex;
        max-width: 100%;
        margin: 0;
        width: 100% !important;
        height: 100%;
        flex-grow: 1;
        
        .block-left {
            position: relative;
            overflow: hidden;
            width: 42%;
            background-repeat: no-repeat;
            background-size: cover; 
            transition: width .3s;
        }

        .block-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 1em;
            margin-bottom: 1em;
            padding: 0 2em;
            width: 58%;

            .progress-container {
                max-width: 40em;
                margin-bottom: 1em;
                margin-top: 1em;
            }

            .content-container {
                margin-top: 0em;
                
                .headline {
                    text-align: left;
                    font-weight: 600;
                    font-size: 2.5em;
                    margin-top: .5em;
                }
                
                .description {
                    text-align: left;
                    margin: 0;
                    margin-top: 2em;
                    font-weight: 300;
                    color: ${variables.dpc_fontDark};
                    line-height: 1.5em;
                }
            }
        
            .back-button {
                margin-bottom: 2em;
            }
        }
    }

    @media (max-width: ${variables.desktopS}) {
        .layout-container {
            .block-right {
                padding: 0 3em;
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .layout-container {
            position: relative;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            .block-left {
                position: absolute;
                height: 100%;
                min-height: -webkit-fill-available;
                min-height: -moz-available;
                width: 100%;
                z-index: 0;
            }

            .block-right {
                width: 100%;
                min-height: 100vh;
                z-index: 1;
                background: #000000cc;
                margin-top: 0;
                margin-bottom: 0;

                .content-container {
                    .public-shop-user-form {
                        .login-wrapper, .confirm-wrapper, .register-wrapper {
                            .view-cols {
                                .view-col-info {
                                    .info-headline {
                                        color: ${variables.dpc_fontWhite};
                                    }
                                    .info-subheadline {
                                        color: ${variables.dpc_fontWhite};
                                    }
                                }
                                
                                .view-col-form {
                                    .public-user-register-form, .component-login-form {
                                        .form-element {
                                            .input-external-wrapper {
                                                .input-label {
                                                    color: ${variables.dpc_fontWhite};
                                                }
                                            }
                                        }
                                        .regulations-container {
                                            .form-element {
                                                .input-external-wrapper {
                                                    .input-internal-wrapper {
                                                        .label {
                                                            color: ${variables.dpc_fontWhite};
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .public-shop-creator-payment-form {
                        background: none;
                        .block {
                            .block-summary {
                                .block-row {
                                    &.block-row-price, &.block-row-period, &.block-row-notice {
                                        .block-row-label {
                                            color: ${variables.dpc_fontWhite};
                                        }
                                        .block-row-value {
                                            color: ${variables.dpc_fontWhite};
                                        }
                                    }
                                }
                            }
                            &.block-promo-code {
                                .promo-code-trigger {
                                    color: ${variables.dpc_fontWhite};

                                    b {
                                        color: ${variables.dpc_fontWhite};
                                    }
                                }

                                .promo-code-wrapper {
                                    .promo-code-container {
                                        &.added {
                                            .promo-code {
                                                color: ${variables.dpc_fontWhite};
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
                .offer-rows {
                    .offer-row {
                        .block-products {
                            .block-list {
                                .block-element {
                                    width: 100%;
                                }
                            }
                        }   
                        .block-pricing {
                            .block-footer {
                                width: 100%;
                                margin-top: 2em;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .layout-container {
            .block-right {
                padding: 0 2em;
            }
        }
    }
`;

