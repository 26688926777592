import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .headline {
            font-size: 2.8em;
            color: ${variables.dpc_fontDark};
            font-weight: 900;
            line-height: 90%;
            text-align: center;
            margin-top: 1em;
        }

        .description {
            margin: 1em auto;
            font-size: 1em;
            color: ${variables.dpc_fontDark};
            max-width: 50em;
            text-align: center;

            b {
                font-weight: 600;
            }
        }

        .block {
            margin-bottom: 2em;
            padding-bottom: 2em;

            .block-headline {
                font-size: 1.6em;
                font-weight: 300;
                color: ${variables.dpc_fontDark};
            }

            .block-list {
                margin-top: 1em;

                .block-list-element {
                    color: black !important;
                }

                .block-element {
                    margin-right: 1em;
                    margin-bottom: .6em;
                    padding-bottom: .6em;
                    color: ${variables.dpc_fontDark};
                    font-size: 1.1em;

                    .block-element-product-name {
                        width: 100%;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .block-summary {
                margin-top: 3em;

                .block-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 1.3em;
                    margin-bottom: .5em;
                    padding-bottom: .5em;

                    .block-row-label {
                        color: black;
                    }
                    .block-row-value {
                        color: #CCCCCC;
                    }
                }

                .block-row-period {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .block-row-value {
                        margin-left: 0 !important;
                        margin-top: 1em;
                    }
                }
            }

            .block-footer {
                margin-top: 2em;
            }

            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        .offer-rows {   
            .offer-row {
                width: 100%;

                &.offer-row-pricing {
                    width: 100%;

                    .block {
                        flex-wrap: wrap;

                        .block-summary {
                            .block-row-period {
                                flex-wrap: wrap;
                            }
                        }
                    }
                }
            }
        }

        .offer-rows {
            margin-top: 1.5em;

            .offer-row {
                .block-usps {
                    margin-bottom: 1em;
                    padding-bottom: 1em;

                    .block-headline {
                        font-size: 1em;
                        font-weight: 600;
                    }

                    .block-list {
                        margin-top: .25em;
                        margin-left: 1.1em;

                        .block-list-element {
                            position: relative;
                            color: white;
                            font-weight: 300;
                            list-style: none;
                            margin-bottom: .3em;

                            &::before {
                                position: absolute;
                                content: '';
                                display: block;
                                width: 8px;
                                height: 8px;
                                border-radius: 100%;
                                background-color: #DA3143;
                                left: -1.1em;
                                top: .25em;
                            }

                            &::marker {
                                display: none;
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .block-products {
                    .block-headline {
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 1.25em;
                    }

                    .block-list {
                        display: flex;
                        flex-wrap: wrap;
                        padding: .5em 0;

                        .block-element {
                            display: flex;
                            flex-direction: column;
                            width: 30%;
                            align-items: center;

                            .block-element-quantity {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                color: transparent;
                                font-weight: 700;
                                font-size: 5.5em;
                                -webkit-text-stroke-width: 2px;
                                -webkit-text-stroke-color: #DA3143;

                                .quantity-prefix {
                                    display: flex;
                                    font-size: .5em;
                                }
                            }

                            .block-element-product-name {
                                margin-top: .3em;
                                font-size: .8em;
                                font-weight: 300;
                                text-transform: uppercase;
                                text-align: center;
                                word-break: break-word;
                                hyphens: auto;
                            }
                        }
                    }
                }

                .block-pricing {
                    margin-top: 1em;
                    display: flex;
                    justify-content: space-between;
                    background: ${variables.dpc_backgroundWhite};
                    padding: 2.4em 2.5em;

                    .block-summary {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-top: 0;
                        margin-right: 1em;

                        .block-row {
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        .block-row, .block-row-price {
                            margin-top: 1em;
                            justify-content: flex-start;
                            
                            .block-row-label, .block-row-value {
                                color: black;
                            }

                            .block-row-value {
                                margin-left: .25em;
                            }

                            &:only-child {
                                margin: 0;
                                color: red;
                            }

                            &:first-of-type {
                                font-size: 2em;
                                
                                .block-row-label, .block-row-value {
                                    color: black;
                                    font-weight: 800;
                                }
                            }

                            &:not(:first-of-type) {
                                margin-bottom: .2em;
                                text-transform: uppercase;
                                font-size: .8em;
                                color: ${variables.dpc_fontDark};
                                font-weight: 300;
                            }

                        }

                    }

                    .block-footer {
                        display: flex;
                        align-items: center;
                        margin-top: 0;
                        width: 30%;

                        .component-button {
                            width: 100%;
                        }
                    }
                }
            }
        } 

        @media (max-width: ${variables.tabletL}) {
            .headline {
                color: ${variables.dpc_fontWhite};
            }

            .description {
                color: ${variables.dpc_fontWhite} !important;
            }
            
            .block {
                .block-headline {
                    color: ${variables.dpc_fontWhite};
                }
                
                .block-list {
                    .block-element {
                        .block-element-product-name {
                            color: white;
                        }
                    }

                    .block-list-element {
                        color: ${variables.dpc_fontWhite} !important;
                    }
                }
            }

            .offer-rows {   
                .offer-row {
                    .block-pricing {
                        background: none;
                        .block-summary {
                            .block-row, .block-row-price {
                                .block-row-label, .block-row-value {
                                    color: white;
                                }
                                
                                &:first-of-type {
                                    .block-row-label, .block-row-value {
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                    &.offer-row-pricing {
                        .block {
                            margin-bottom: 2em;
                        }
                    }
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {}
    `;
