import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { PUBLIC_SHOP_PAYMENT, ADEPT_DASHBOARD } from 'Consts/routes';
import { API_RESOURCE_REDIRECT_URI } from 'Consts/apiResources';
import { 
    OFFER_PERIOD_TYPE_SINGLE, 
    OFFER_PERIOD_TYPE_RECURRING, 
    OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH, 
    OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING,
    OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY,
} from 'Consts/offers';
import { PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT } from 'Consts/paymentTransactions';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import StepProgress from 'Components/layout/StepProgress';
import UserForm from 'Components/public/shop/UserForm';
import PaymentTypes from 'Components/public/shop/PaymentTypes';
import PaymentCheckout from 'Components/public/shop/PaymentCheckout';
import OfferPresentation from 'Components/public/shop/OfferPresentation';
import Button from 'Components/layout/Button';
import Spinner from 'Components/layout/Spinner';

export default class PublicShopOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        authToken: PropTypes.string,
        profile: PropTypes.object,
        match: PropTypes.shape({
            params: PropTypes.shape({
                slug: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            singleBySlug: PropTypes.func.isRequired,
            getProfile: PropTypes.func.isRequired,
        }).isRequired,
        offer: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {
        currentStep: 0,
        isPending: false,
        paymentType: null,
        paymentMethod: null,
        promoCode: null,
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.slug !== params.slug) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.singleBySlug({ slug: params.slug });
    }

    onSubmit = () => {
        const { history, actions, offer } = this.props;
        const { promoCode, paymentType, paymentMethod } = this.state;

        this.setState({
            isPending: true,
            globalError: null,
        }, () => {
            actions.create({
                offerId: offer.data?.id,
                transactionType: paymentType,
                paymentMethodId: paymentMethod?.id,
                promoCodeId: promoCode?.id,
                continueUrl: process.env.APP_URL + withVariables(PUBLIC_SHOP_PAYMENT.path),
            })
                .then(response => {
                    const data = response.payload;
                    if (data[API_RESOURCE_REDIRECT_URI]) {
                        window.location.href = data[API_RESOURCE_REDIRECT_URI];
                    } else {
                        toast('Zamówienie zostało utworzone');
                        history.push(ADEPT_DASHBOARD.path);
                    }
                })
                .catch(error => {
                    toast('Nie udało się utworzyć zamówienia. Zweryfikuj wprowadzone informacje i spróbuj ponownie');
                    Logger.error('[SHOP] CreateOrder Error', { error, offer, paymentType, promoCode, paymentMethod });
                    this.setState({
                        isPending: false,
                        errors: error.payload.validationErrors,
                        globalError: error.payload.message,
                    });
                });
        });
    }

    onAddPromoCode = (data) => {
        if(data) {
            const { promoCode } = data;
            this.setState({ promoCode });
        } else {
            this.setState({ promoCode: null });
        }
    }

    render() {
        const { location, history, offer, profile } = this.props;
        const { paymentType, paymentMethod, promoCode, isPending } = this.state;
        let { currentStep } = this.state;

        if(!offer || !offer.data) {
            return (<Spinner />);
        }

        const steps = [{
            label: 'Oferta',
            key: 'offer',
            onClick: () => this.setState({ currentStep: 0 }),
        }, {
            label: 'Twoje dane',
            key: 'user',
            onClick: () => this.setState({ currentStep: 1 }),
        }, {
            label: 'Płatność',
            key: 'payment',
            onClick: () => profile && this.setState({ currentStep: 2 }),
        }];

        let availablePaymentTypes = [];
        if(offer.data.firstPaymentMethodType) {
            switch (offer.data.firstPaymentMethodType) {
                case OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH:
                    availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT];
                    break;
                case OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING:
                    availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_DEFAULT];
                    break;
                case OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY:
                    availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE];
                    break;
            }
        } else {
            if(offer.data.periodType === OFFER_PERIOD_TYPE_SINGLE) {
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT];
            }
            if(offer.data.periodType === OFFER_PERIOD_TYPE_RECURRING) {
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_DEFAULT];
            }
        }

        if(currentStep === 2 && !profile) {
            currentStep = 1;
        }

        return (
            <StyledComponent
                className="public-shop-offer"
                styles={require('./styles')}
            >
                <Head title="Sklep" />
                <LayoutContainer>
                    <div 
                        style={{ backgroundImage: offer?.data?.backgroundUrl ? `url(${offer.data.backgroundUrl})` : `url(${require('Theme/images/shop/shop_offer.jpeg')})` }} 
                        className="block block-left"
                    />
                    <div className="block block-right">
                        <div className="progress-container">
                            <StepProgress
                                showControls={false}
                                currentStep={currentStep}
                                steps={steps}
                                type='simple'
                            />
                        </div>
                        <div className="content-container">
                            {steps[currentStep].key === 'offer' && (
                                <OfferPresentation
                                    offer={offer.data}
                                    onComplete={() => this.setState({ currentStep: currentStep + 1 })}
                                />
                            )}
                            {steps[currentStep].key === 'user' && (
                                <UserForm
                                    history={history}
                                    shopType="offer"
                                    onComplete={() => this.setState({ currentStep: currentStep + 1 })}
                                />
                            )}
                            {steps[currentStep].key === 'payment' && Boolean(profile) && (
                                <>
                                    <PaymentCheckout 
                                        location={location}
                                        history={history}
                                        paymentType={paymentType}
                                        paymentMethod={paymentMethod}
                                        promoCode={promoCode}
                                        onAddPromoCode={this.onAddPromoCode}
                                        onSubmit={this.onSubmit}
                                        isPending={isPending}
                                        data={{
                                            offerId: offer.data.id,
                                            periodType: offer.data.periodType,
                                            totalPriceGross: offer.data.totalPriceGross,
                                            totalDiscountedPriceGross: offer.data.totalPriceGross,
                                            durationValue: offer.data.durationValue,
                                            durationType: offer.data.durationType,
                                            noticePeriodDurationValue: offer.data.noticePeriodDurationValue,
                                            noticePeriodDurationType: offer.data.noticePeriodDurationType,
                                        }}
                                    />
                                    <PaymentTypes 
                                        location={location}
                                        history={history}
                                        availablePaymentTypes={availablePaymentTypes}
                                        paymentType={paymentType}
                                        paymentMethod={paymentMethod}
                                        onSelectPaymentType={paymentType => this.setState({ paymentType })}
                                        onSelectPaymentMethod={paymentMethod => this.setState({ paymentMethod })}
                                    />
                                </>
                            )}
                        </div>
                        {currentStep > 0 && (
                            <Button
                                className="back-button"
                                onClick={() => this.setState({ currentStep: currentStep - 1 })}
                                style='gradient'
                            >
                                Cofnij
                            </Button>
                        )}
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
